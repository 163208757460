<template>
  <PageWrapper>
    <PagePanel>
      <PageSearchArea buttonTestId="UPDATE" :showButton="false">
        <template #form>
          <el-form label-position="top">
            <AccountNumber ref="accountNumber"></AccountNumber>
            <el-form-item :label="$t('referralLinks.campLang')">
              <el-select v-model="language" @change="languageSelectChange" data-testid="language">
                <el-option
                  v-for="item in languageList"
                  :key="item.language"
                  :label="item.language"
                  :value="item.value"
                  :data-testid="item.language"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
      </PageSearchArea>
    </PagePanel>
    <PagePanel>
      <el-row :gutter="40" v-if="accountID">
        <el-col
          :span="8"
          :lg="8"
          :md="12"
          :sm="12"
          :xs="24"
          v-for="(link, index) in linkList"
          v-bind:key="link.name"
          :class="{ active: active == index }"
          @click="active = index"
          :data-testid="link.name"
          class="copy_line_box"
        >
          <div class="link_item">
            <div class="content">
              <p>{{ link.name }}</p>
              <p>{{ link.url }}</p>
            </div>
            <div class="shadow">
              <div class="copy" @click="onCopy(link.url)" :data-testid="'copy-' + link.name">
                <span>{{ $t('referralLinks.cpy') }}</span>
              </div>
              <div class="code">
                <vue-qrcode :color="color" :value="link.url || GLOBAL_REFERRAL_SITE" :class="index" />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </PagePanel>
  </PageWrapper>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import AccountNumber from '@/components/customUI/AccountNumber';

export default {
  name: 'ReferralLinks',
  components: { AccountNumber, VueQrcode },
  data() {
    return {
      language: '',
      languageList: {
        English: { language: this.$t('common.lang.english'), value: '' },
        Chinese: { language: this.$t('common.lang.chinese'), value: 'cn' },
        French: { language: this.$t('common.lang.french'), value: 'fr' },
        Thai: { language: this.$t('common.lang.thai'), value: 'th' },
        German: { language: this.$t('common.lang.german'), value: 'de' },
        Spanish: { language: this.$t('common.lang.spanish'), value: 'es' },
        Malay: { language: this.$t('common.lang.malay'), value: 'my' },
        Vietnamese: { language: this.$t('common.lang.viet'), value: 'vn' },
        Indonesian: { language: this.$t('common.lang.indo'), value: 'id' },
        Arabic: { language: this.$t('common.lang.arabic'), value: 'ar' }
      },
      linkList: {
        liveAccountLink: { name: this.$t('referralLinks.liveAcc'), url: '' },
        demoAccountLink: { name: this.$t('referralLinks.demoAcc'), url: '' },
        homePageLink: { name: this.$t('referralLinks.homePage'), url: '' }
      },
      color: { dark: '#000000', light: '#ffffffff' },
      active: ''
    };
  },
  watch: {
    accountID() {
      console.log(this.accountID);
      if (this.accountID) this.setLinkList();
    }
  },
  methods: {
    onCopy(item) {
      this.$copyText(item)
        .then(result => {
          this.$message(this.$t('referralLinks.alreadyCPY'));
        })
        .catch(err => {
          this.$message('Failed to copy' + err);
        });
    },
    async convertBase64ToBlob(base64) {
      const response = await fetch(base64);
      const blob = await response.blob();
      const data = [new ClipboardItem({ [blob.type]: blob })];
      await navigator.clipboard.write(data); //only https
      this.$message(this.$t('referralLinks.alreadyCPY'));
    },
    onCopyImg(name) {
      this.convertBase64ToBlob(document.getElementsByClassName(name)[0].src);
    },
    languageSelectChange(item) {
      if (this.accountID != null) this.setLinkList();
    },
    setLinkList() {
      const liveAccount = '/forex-trading-account';
      const demoAccount = '/trading/accounts/demo-account';
      const { GLOBAL_REFERRAL_SITE } = this;

      this.linkList.liveAccountLink.url = `${GLOBAL_REFERRAL_SITE}${liveAccount}?affid=${this.accountID}`;
      this.linkList.demoAccountLink.url = `${GLOBAL_REFERRAL_SITE}${demoAccount}?affid=${this.accountID}`;
      this.linkList.homePageLink.url = `${GLOBAL_REFERRAL_SITE}`;
    },
    setDefaultLanguage(countryCode) {
      switch (countryCode) {
        case '1':
          this.language = this.languageList.Chinese.value;
          break;
        case '3416':
          this.language = this.languageList.Arabic.value;
          break;
        case '3915':
          this.language = this.languageList.German.value;
          break;
        case '4101':
          this.language = this.languageList.French.value;
          break;
        case '5015':
          this.language = this.languageList.Malay.value;
          break;
        case '6163':
          this.language = this.languageList.Thai.value;
          break;
        case '6581':
          this.language = this.languageList.Spain.value;
          break;
        case '6877':
          this.language = this.languageList.Indonesian.value;
          break;
        case '6991':
          this.language = this.languageList.Vietnamese.value;
          break;
        default:
          this.language = '';
      }
    }
  },
  mounted() {
    this.setDefaultLanguage(this.$store.state.common.countryCode);
    this.setLinkList();
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/referralLinks.scss';
.copy_line_box {
  display: flex;
  justify-content: space-between;

  .link_item {
    position: relative;
    height: 156px;
    background: #f8f8fd;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    margin-bottom: 40px;

    @include screen-mobile {
      margin-bottom: 24px;
    }

    .content {
      padding: 16px 40px;

      p:nth-child(1) {
        font-size: 16px;
        color: #1d1d1f;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 24px;
      }

      p:nth-child(2) {
        font-size: 14px;
        color: #6e6e73;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    &:hover,
    &:focus,
    &:active {
      .shadow {
        animation: shadowShow 0.2s linear forwards;
      }
    }

    .shadow {
      width: 100%;
      position: absolute;
      bottom: -100%;
      height: 100%;
      background: rgba(29, 29, 31, 0.68);
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .copy {
        span {
          display: inline-block;
          font-size: 16px;
          color: #1d1d1f;
          line-height: 24px;
          font-weight: 500;
          background: $white;
          padding: 8px 30px;
          border-radius: 8px;
          cursor: pointer;
        }
      }

      .code {
        img {
          width: 100px;
          height: 100px;
          border-radius: 8px;
        }
      }
    }
  }
}

@keyframes shadowShow {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0%;
  }
}

@-webkit-keyframes shadowShow {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0%;
  }
}
</style>
